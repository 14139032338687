import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import { Link } from 'gatsby'
import privacyPolicy from '../assets/images/privacy-policy.jpg'

const PrivacyPolicy = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Politica de Confidențialitate"
                homePageText="Informații utile"
                homePageUrl="/" 
                activePageText="Politica de Confidențialitate"
            />
            <section className="privacy-policy-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="privacy-policy-content">
                                <img src={privacyPolicy} alt="pp" />
                                <p><i>Această politică de confidențialitate a fost actualizată ultima dată la 16 Ianuarie, 2023.</i></p>
                                <h3>Politica noastră de confidențialitate</h3>
                                <p>La Vertical Digital, ne angajăm să protejăm confidențialitatea și securitatea clienților și vizitatorilor site-ului nostru. Înțelegem importanța protecției datelor personale și am implementat măsuri stricte pentru a vă asigura că informațiile dumneavoastră sunt gestionate în conformitate cu Regulamentul privind protecția datelor generale (GDPR).</p>
                                <h3>Proprietate intelectuală</h3>
                                <p>Toate conținuturile site-ului nostru, inclusiv, dar fără a se limita la text, imagini și cod, sunt protejate de drepturi de autor și alte legi privind proprietatea intelectuală. Avem dreptul de proprietate sau am obținut drepturile necesare pentru a utiliza toate conținuturile site-ului nostru.</p>
                                <h3>Date personale</h3>
                                <p>Colectăm și procesăm datele personale atunci când vizitați site-ul nostru, solicitați informații sau utilizați serviciile noastre. Datele personale includ informații precum numele dumneavoastră, adresa de email și numărul de telefon. De asemenea, putem colecta alte informații, cum ar fi adresa IP și istoricul de navigare.</p>
                                <p>Folosim datele personale pe care le colectăm pentru a vă oferi informații despre serviciile noastre, a comunica cu dumneavoastră și a îmbunătăți experiența dumneavoastră pe site-ul nostru. Nu împărtășim datele personale cu terțe părți, cu excepția cazului în care acest lucru este necesar pentru a oferi serviciile noastre sau conform cerințelor legale.</p>
                                <h3>Păstrarea datelor</h3>
                                <p>Vom păstra datele personale dumneavoastră pentru perioada necesară pentru a oferi serviciile noastre sau conform cerințelor legale. Când datele personale nu mai sunt necesare, le vom șterge în siguranță.</p>
                                <h3>Securitatea datelor</h3>
                                <p>Luăm în serios securitatea datelor personale și am implementat măsuri tehnice și organizaționale adecvate pentru a le proteja de acces neautorizat, utilizare sau divulgare.</p>
                                <h3>Drepturile dumneavoastră</h3>
                                <p>Conform GDPR, aveți dreptul să:</p>
                                <ul>
                                   <li>Solicitați acces la datele personale</li>
                                   <li>Solicitați rectificarea sau ștergerea datelor personale</li>
                                   <li>Solicitați restricționarea prelucrării datelor personale</li>
                                   <li>Obiecționați la prelucrarea datelor personale</li>
                                   <li>Solicitați portabilitatea datelor</li>
                                </ul>
                                <p>Dacă doriți să exercitați oricare dintre aceste drepturi, vă rugăm să ne contactați la info@verticaldigital.ca.</p>
                                <h3>Modificări ale politicii noastre de confidențialitate</h3>
                                <p>Putem actualiza această politica de confidențialitate din când în când. Vă vom notifica orice schimbări prin postarea politicii actualizate pe site-ul nostru.</p>
                                <p>Dacă aveți întrebări despre politica noastră de confidențialitate sau despre modul în care gestionăm datele personale, vă rugăm să ne contactați la info@verticaldigital.ca</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <aside className="widget-area">
                                <div className="widget widget_insight">
                                    <ul>
                                        <li>
                                            <Link to="/despre-noi/">
                                                Despre noi
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/contact/">
                                                Contact
                                            </Link>
                                        </li>
                                        <li className="active">
                                            <Link to="/politica-de-confidentialitate/">
                                                Politica de Confidențialitate
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </Layout>
    )
}

export default PrivacyPolicy;